import React, {useMemo, useState} from 'react';
import {Button, Stack, Typography, Box} from '@mui/material';
import Modal from '@mui/material/Modal';
import {observer} from 'mobx-react-lite';
import TextField from '@mui/material/TextField';
import '../../styles/main.css';
import {useTranslation} from 'react-i18next';
import {useRootStore} from '../../context';

interface ICreateCodeModalProps {
  handleCreateCode: (
    e: React.SyntheticEvent,
    deviceId: string,
    me: string | null,
    codeName: string,
    code: string,
  ) => Promise<void>;
  isOpen: boolean;
  onClose: () => void;
  deviceId: string;
  me: string | undefined;
}

const CreateCodeModal: React.FC<ICreateCodeModalProps> = observer(
  ({handleCreateCode, isOpen, onClose, deviceId, me}) => {
    const {lockDevicesStore} = useRootStore();

    const [codeName, setCodeName] = useState('');
    const [code, setCode] = useState('');
    const [isValidName, setIsValidName] = useState<boolean>(true);
    const [isValidCode, setIsValidCode] = useState<boolean>(true);

    const {t} = useTranslation();

    const registeredCodes = useMemo(
      () => lockDevicesStore.selectedDeviceCodes.map((device) => device.code),
      [lockDevicesStore.selectedDeviceCodes],
    );

    const style = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      bgcolor: 'background.default',
      border: '1px solid #000',
      boxShadow: 24,
      p: 4,
    };

    const createCodeHandler = async (e: React.SyntheticEvent) => {
      if (codeName.length === 0 || code.length < 4) return;

      onClose();

      try {
        await handleCreateCode(e, deviceId, me ?? null, codeName, code);
      } catch (error) {
        console.log('Error creating new code', error);
      }

      // Reset state
      setCodeName('');
      setCode('');
      setIsValidName(true);
      setIsValidCode(true);
    };

    const nameChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.value;
      setCodeName(name);

      // Validate the name is not Code 1, code 2... etc
      const pattern = /^code\s\d+$/i;
      setIsValidName(!pattern.test(name));
    };

    const codeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
      const sanitized = event.target.value.replace(/[^0-9]/g, '');
      setCode(sanitized);

      // Validate the code doesn't exist yet
      setIsValidCode(!registeredCodes.includes(sanitized));
    };

    return (
      <>
        <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={style}
            width={{xs: '70%', sm: 'auto'}}
            justifyContent="center"
            alignItems={'center'}
            display="flex"
            flexDirection={'column'}
          >
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please enter a name and a code
            </Typography>
            <TextField
              id="outlined-basic"
              label="Code name"
              placeholder="64 Characters max."
              variant="outlined"
              margin="normal"
              value={codeName}
              onChange={nameChangeHandler}
              multiline={false}
              inputProps={{maxLength: 64}}
              fullWidth={true}
            />
            {!isValidName && <Typography color={'red'}>This name is not allowed</Typography>}

            <TextField
              id="outlined-basic"
              label="Code"
              placeholder="Max 4 numeric characters"
              variant="outlined"
              margin="normal"
              value={code}
              onChange={codeChangeHandler}
              multiline={false}
              inputProps={{maxLength: 4}}
              fullWidth={true}
            />

            {!isValidCode && <Typography color={'red'}>This code is already registered</Typography>}

            <Stack flexDirection="row" gap={1} justifyContent="flex-end" marginTop={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={createCodeHandler}
                disabled={codeName.length === 0 || code.length < 4 || !isValidCode || !isValidName}
              >
                {t('lockDevices:create_code')}
              </Button>
              <Button variant="contained" color="error" onClick={onClose}>
                {t('common:cancel')}
              </Button>
            </Stack>
          </Box>
        </Modal>
      </>
    );
  },
);

export default CreateCodeModal;
