import React from 'react';
import BackpackIcon from '@mui/icons-material/Backpack';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import DonutSmallIcon from '@mui/icons-material/DonutSmall';
import ChatIcon from '@mui/icons-material/Chat';
import DryCleaningIcon from '@mui/icons-material/DryCleaning';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SettingsIcon from '@mui/icons-material/Settings';
import {Box, Divider, IconButton, List, Toolbar} from '@mui/material';
import Logo from '../Logo';
import NavLink from './NavLink';
import NavAccordion from './NavAccordion';
import {useTranslation} from 'react-i18next';
import Config from '../../config';
import {materia2URLs} from '../../libs/constants';

interface INavLinksProps {
  handleDrawerToggle: () => void;
  isOpen: boolean;
}

const NavLinks: React.FC<INavLinksProps> = ({handleDrawerToggle, isOpen}) => {
  const {t} = useTranslation();

  const materia2UrlKeys = Config.ENV as keyof typeof materia2URLs;

  const MAIN_LINKS = [
    /*  {text: 'Overview', icon: <DonutSmallIcon />, link: '/overview'}, */
    {
      text: t('common:navbar_linens_and_towels'),
      icon: <DryCleaningIcon />,
      link: '/linens-towels',
    },
    {
      text: t('common:navbar_inventory'),
      icon: <LightbulbIcon />,
      subLinks: [
        {
          text: t('common:navbar_stock_count'),
          link: '/inventory/stock',
        },
        {
          text: t('common:navbar_deliveries_alerts'),
          link: '/inventory/deliveries-alerts',
        },
        {
          text: t('common:navbar_all_items'),
          link: '/inventory/items',
        },
        {
          text: t('common:navbar_vendors'),
          link: '/inventory/vendor',
        },
      ],
    },
    {
      text: t('common:navbar_travelers'),
      icon: <BackpackIcon />,
      link: '/travelers',
      subLinks: [
        {
          text: t('common:navbar_reservations'),
          link: `${materia2URLs[materia2UrlKeys].reservations}`,
          //link: '/travelers/reservations',
          direct: true,
        },
        {
          text: t('common:reservation_products'),
          link: '/travelers/reservation-products',
        },
        {
          text: t('common:navbar_lock_devices'),
          link: '/travelers/access',
        },
        {
          text: t('common:navbar_guests'),
          link: '/travelers/guests',
        },
      ],
    },

    {
      text: 'Door locks',
      icon: <MeetingRoomIcon />,
      link: '/door-locks',
    },
    {
      text: 'Tasks',
      icon: <CalendarViewMonthIcon />,
      link: `${materia2URLs[materia2UrlKeys].tasks}`,
      direct: true,
    },
    {
      text: 'Chat',
      icon: <ChatIcon />,
      link: `${materia2URLs[materia2UrlKeys].chat}`,
      direct: true,
    },
    /* {text: t('common:navbar_team'), icon: <PeopleAltIcon />, link: '/team'}, */
    /* {
      text: 'Ops Procedures',
      icon: <FileCopyIcon />,
      link: '/procedures',
    }, */
    /*  {text: t('common:navbar_maintenance'), icon: <EngineeringIcon />, link: '/maintenance'}, */
  ];

  const SUB_LINKS = [
    {text: t('common:navbar_settings'), icon: <SettingsIcon />, link: '/settings'},
    {
      text: t('common:navbar_admin'),
      icon: <PeopleAltIcon />,
      link: process.env.REACT_APP_ADMIN_URL,
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: '#363740',
        color: '#FFF',
      }}
    >
      <Toolbar
        sx={{
          display: {xs: 'none', lg: 'block'},
          padding: '1rem 0 !important',
          marginInline: '1.5rem',
          minHeight: '0 !important',
          width: '231px',
        }}
      >
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          sx={{
            marginRight: 2,
          }}
        >
          <MenuIcon />
        </IconButton>
        <Logo variant="light" />
      </Toolbar>
      <Divider sx={{opacity: 0.15}} />
      {/* -------------------------- Main Links ---------------------------- */}
      <List
        sx={{
          position: 'relative',
          '& .MuiListItem-root': {
            '&:hover': {
              backgroundColor: '#00000030',
            },
          },
        }}
      >
        {MAIN_LINKS.map((link) => {
          if (link.subLinks) {
            return (
              <NavAccordion
                key={link.text}
                name={link.text}
                icon={link.icon}
                links={link.subLinks}
                isOpen={isOpen}
              />
            );
          }
          return <NavLink key={link.text} link={link} />;
        })}
      </List>
      {/* -------------------------- Main Links ---------------------------- */}
      {/* --------------------------- Sub Links ---------------------------- */}
      <Divider sx={{opacity: 0.15}} />
      <List>
        {SUB_LINKS.map((link) => {
          return <NavLink key={link.text} link={link} />;
        })}
      </List>
      {/* --------------------------- Sub Links ---------------------------- */}
    </Box>
  );
};

export default NavLinks;
