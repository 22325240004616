import React, {useEffect} from 'react';
import {Button, Card, CardContent, Container, Grid, Stack, Typography, Box} from '@mui/material';
import {useState} from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Header from '../../components/table/Header';
import CardRowItem from '../../components/table/CardRowItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import {observer} from 'mobx-react-lite';
import {useParams, Link} from 'react-router-dom';
import SelectHotel from '../../components/Navbar/SelectHotel';
import PageTitle from '../../components/PageTitle';
import {useRootStore} from '../../context';
import CreateCodeModal from './CreateCodeModal';
import DeleteCodeModal from './DeleteCodeModal';
import {useTranslation} from 'react-i18next';
import NoResultsFound from '../../components/general/NoResultsFound';

import '../../styles/main.css';
import SkeletonLoader from '../../components/general/SkeletonLoader';

const DeviceDetails = observer(() => {
  const {t} = useTranslation();
  const {lockDevicesStore, hotelStore} = useRootStore();

  const {deviceName, deviceId, me} = useParams();
  const [codeNameModalOpen, setCodeNameModalOpen] = useState(false);
  const [deleteCodeModalOpen, setDeleteCodeModalOpen] = useState(false);
  const [selectedCodeForDelete, setSelectedCodeForDelete] = useState('');

  if (deviceId) lockDevicesStore.setSelectedDevice(deviceId);
  if (me) lockDevicesStore.setSelectedDeviceMe(me);

  useEffect(() => {
    if (hotelStore.currentHotel !== undefined && deviceId) {
      lockDevicesStore.getSelectedDeviceCodes(deviceId, me ?? null);
    }
  }, [lockDevicesStore.selectedDeviceCodes.length]);

  const createAccessCodeButton = (
    <Stack display="flex" flexDirection={'row'} justifyContent={'flex-end'} marginBottom={2}>
      <Button
        variant="contained"
        endIcon={<AddOutlinedIcon />}
        aria-label={'Create code'}
        color={'primary'}
        onClick={() => setCodeNameModalOpen(true)}
      >
        {t('lockDevices:create_code')}
      </Button>
    </Stack>
  );

  const closeCodeNameModalHandler = () => {
    setCodeNameModalOpen(false);
  };

  const closedeleteCodeModalHandler = () => {
    setDeleteCodeModalOpen(false);
  };

  const createCodeHandler = async (
    e: React.SyntheticEvent,
    deviceId: string,
    me: string | null,
    codeName: string,
    code: string,
  ) => {
    try {
      await lockDevicesStore.createAccessCode(deviceId, me, codeName, code);

      window.location.reload();
    } catch (error) {
      console.log('Error creating code', error);
    }
  };

  const deleteCodeHandler = async (codeId: string, me: string | null) => {
    try {
      await lockDevicesStore.deleteAccessCode(codeId, me);

      window.location.reload();
    } catch (error) {
      console.log('Error deleting code', error);
    }
  };

  const handleDelete = (e: React.SyntheticEvent, codeId: string) => {
    setSelectedCodeForDelete(codeId);
    setDeleteCodeModalOpen(true);
  };

  return (
    <>
      {deviceId && (
        <CreateCodeModal
          handleCreateCode={createCodeHandler}
          isOpen={codeNameModalOpen}
          onClose={closeCodeNameModalHandler}
          deviceId={deviceId}
          me={me}
        />
      )}

      <DeleteCodeModal
        handleDeleteCode={deleteCodeHandler}
        isOpen={deleteCodeModalOpen}
        onClose={closedeleteCodeModalHandler}
        codeId={selectedCodeForDelete}
        me={me}
      />

      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageTitle>{t('common:navbar_travelers')}</PageTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <SelectHotel disabled={true} />
          </Grid>
        </Grid>

        <Card sx={{marginY: 1}}>
          <CardContent>
            <Link to="/travelers/access" style={{textDecoration: 'none'}}>
              <Button variant="text" startIcon={<ChevronLeftIcon fontSize="large" />} size="large">
                {t('lockDevices:all_devices')}
              </Button>
            </Link>

            {createAccessCodeButton}
            <Typography variant="h5" component="h5" marginBottom={3}>
              {`${deviceName} ${t('lockDevices:device_access_codes')}`}
            </Typography>

            {lockDevicesStore.isLoading && <SkeletonLoader skeletonShape="table" amount={2} />}

            {lockDevicesStore.selectedDeviceCodes &&
              lockDevicesStore.selectedDeviceCodes.length > 0 &&
              !lockDevicesStore.isLoading && (
                <>
                  <Box marginTop={3}>
                    <Grid container display={{xs: 'none', lg: 'flex'}} mt={3}>
                      <Header lg={3}>{t('common:name')}</Header>
                      <Header lg={3}>{t('common:status')}</Header>
                      <Header lg={3}>{t('common:code')}</Header>
                      <Header lg={3}>{t('common:actions')}</Header>
                    </Grid>
                    <Grid container gap={{xs: 5, lg: 0}}>
                      {lockDevicesStore.selectedDeviceCodes.map((accessCode) => {
                        const info = [
                          {
                            label: t('common:name'),
                            value: accessCode.name ?? '-',
                            lg: 3,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:status'),
                            value: accessCode.status,
                            lg: 3,
                            alignItems: 'center',
                          },
                          {
                            label: t('common:code'),
                            value: accessCode.code,
                            lg: 3,
                            alignItems: 'center',
                          },
                          {
                            label: 'Actions',
                            value: (
                              <>
                                <Tooltip title="Delete access code">
                                  <IconButton
                                    aria-label="Delete access code"
                                    color="error"
                                    onClick={(e) => handleDelete(e, accessCode.access_code_id)}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </Tooltip>
                              </>
                            ),
                            lg: 3,
                            alignItems: 'center',
                          },
                        ];
                        return <CardRowItem item={info} key={accessCode.access_code_id} />;
                      })}
                    </Grid>
                  </Box>
                </>
              )}

            {lockDevicesStore.selectedDeviceCodes.length === 0 && !lockDevicesStore.isLoading && (
              <NoResultsFound message={t('lockDevices:device_has_no_codes')} />
            )}
          </CardContent>
        </Card>
      </Container>
    </>
  );
});

export default DeviceDetails;
