export const materia2URLs = {
  DEV: {
    reservations: 'https://staging.materia2.kabinhotel.xyz/reservations',
    tasks: 'https://staging.materia2.kabinhotel.xyz/tasks',
    chat: 'https://staging.materia2.kabinhotel.xyz/chat',
  },
  STAGING: {
    reservations: 'https://staging.materia2.kabinhotel.xyz/reservations',
    tasks: 'https://staging.materia2.kabinhotel.xyz/tasks',
    chat: 'https://staging.materia2.kabinhotel.xyz/chat',
  },
  QA: {
    reservations: 'https://qa.materia2.kabinhotel.xyz/reservations',
    tasks: 'https://qa.materia2.kabinhotel.xyz/tasks',
    chat: 'https://qa.materia2.kabinhotel.xyz/chat',
  },
  PROD: {
    reservations: 'https://materia2.kabinhotel.xyz/reservations',
    tasks: 'https://materia2.kabinhotel.xyz/tasks',
    chat: 'https://materia2.kabinhotel.xyz/chat',
  },
};
