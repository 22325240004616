import {makeObservable, action, observable, autorun, IReactionDisposer} from 'mobx';
import {StatusCodes} from 'http-status-codes';
import Api, {TGetDeviceAccessCodesResponse, TGetLockDevicesResponse} from '../api/lockDevices';
import {toast} from 'react-toastify';
import i18n from '../i18n';
import type RootStore from './rootStore';
import {handleUnauthorizedResponse} from '../libs/utils';

interface ILockDevices {
  rootStore: RootStore;
  devices: TGetLockDevicesResponse;
  selectedDevice: string;
  selectedDeviceMe: string | null;
  selectedDeviceCodes: TGetDeviceAccessCodesResponse;
  updatedCodeList: boolean;
  isLoading: boolean;
  next: string | null;
  previous: string | null;
  count: number;
  disposers: Array<IReactionDisposer> | IReactionDisposer;

  getDevices: (nextPreviousUrl: string | null) => Promise<void>;
  getSelectedDeviceCodes: (deviceId: string, me: string | null) => Promise<void>;
  createAccessCode: (
    deviceId: string,
    me: string | null,
    codeName: string,
    code: string,
  ) => Promise<void>;
  deleteAccessCode: (accessCodeId: string, me: string | null) => Promise<void>;
  setCount: (count: number) => void;
  setNext: (next: string | null) => void;
  setPrevious: (previous: string | null) => void;
  reset: () => void;
}

class LockDevices implements ILockDevices {
  rootStore: RootStore;
  devices: TGetLockDevicesResponse = [];
  selectedDevice = '';
  selectedDeviceMe: string | null = null;
  selectedDeviceCodes: TGetDeviceAccessCodesResponse = [];
  updatedCodeList = false;
  isLoading = false;
  next: string | null = null;
  previous: string | null = null;
  count = 0;
  disposers: IReactionDisposer | IReactionDisposer[];

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeObservable(this, {
      devices: observable,
      selectedDevice: observable,
      selectedDeviceMe: observable,
      selectedDeviceCodes: observable,
      updatedCodeList: observable,
      isLoading: observable,
      next: observable,
      previous: observable,
      count: observable,

      setDevices: action,
      setSelectedDevice: action,
      setSelectedDeviceMe: action,
      setSelectedDeviceCodes: action,
      setUpdatedCodeList: action,
      setIsLoading: action,
      setCount: action,

      getDevices: action,
      getSelectedDeviceCodes: action,
      createAccessCode: action,
      deleteAccessCode: action,

      reset: action,
    });

    this.disposers = [
      autorun(() => {
        if (this.updatedCodeList === true) {
          this.getSelectedDeviceCodes(this.selectedDevice, this.selectedDeviceMe);
          this.updatedCodeList = false;
        }
      }),
    ];
  }

  reset() {
    this.devices = [];
    this.selectedDevice = '';
    this.selectedDeviceCodes = [];
    this.updatedCodeList = false;
    this.isLoading = false;
    this.count = 0;
  }

  setDevices(devicesList: TGetLockDevicesResponse) {
    this.devices = devicesList;
  }

  setSelectedDevice(deviceId: string) {
    this.selectedDevice = deviceId;
  }

  setSelectedDeviceMe(deviceMe: string) {
    this.selectedDeviceMe = deviceMe;
  }

  setSelectedDeviceCodes(codesList: TGetDeviceAccessCodesResponse) {
    this.selectedDeviceCodes = codesList;
  }

  setUpdatedCodeList(codeListUpdated: boolean) {
    this.updatedCodeList = codeListUpdated;
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setNext(next: string | null) {
    this.next = next;
  }

  setPrevious(previous: string | null) {
    this.previous = previous;
  }

  setCount(count: number) {
    this.count = count;
  }

  async getDevices(nextPreviousUrl: string | null = null) {
    const token = this.rootStore.accountStore.user.token;
    const currentHotel = this.rootStore.hotelStore.currentHotel?.id;

    if (token && currentHotel) {
      try {
        this.setIsLoading(true);

        const response = await Api.getDevices(token, currentHotel, nextPreviousUrl);

        if (response.ok) {
          const data: TGetLockDevicesResponse = await response.body;
          this.setDevices(data);
          this.setCount(data.length);
        }
      } catch (error: any) {
        this.reset();
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async getSelectedDeviceCodes(selectedDevice: string, me: string | null) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await Api.getDeviceAccessCodes(token, selectedDevice, me);
        if (response.ok) {
          const data: TGetDeviceAccessCodesResponse = await response.body;

          this.setSelectedDeviceCodes(data);
        }
      } catch (error: any) {
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async createAccessCode(deviceId: string, me: string | null, codeName: string, code: string) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await Api.createCode({token, deviceId, me, codeName, code});

        if (response.ok) {
          toast.success(i18n.t('Created new access code') as string);
          this.setUpdatedCodeList(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }

  async deleteAccessCode(accessCodeId: string, me: string | null) {
    const token = this.rootStore.accountStore.user.token;

    if (token) {
      try {
        this.setIsLoading(true);

        const response = await Api.deleteAccessCode(token, accessCodeId, me);

        if (response.ok) {
          toast.success(i18n.t('Deleted access code') as string);
          this.setUpdatedCodeList(true);
        }
      } catch (error: any) {
        toast.error(error.message);
        if (error.status === StatusCodes.UNAUTHORIZED) {
          handleUnauthorizedResponse();
          return;
        }
      } finally {
        this.setIsLoading(false);
      }
    }
  }
}

export default LockDevices;
